import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

// https://micahjon.com/2021/pwa-twa-detection/
// https://micahjon.com/2021/play-store-twa-payments/
@Injectable({
  providedIn: 'root',
})
export class TwaService {
  private initialized = false;
  private _isTwa = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  init() {
    if (this.initialized) {
      return;
    }

    if (this.document?.referrer?.includes('android-app://de.member')) {
      this._isTwa = true;
      sessionStorage?.setItem('has_android_twa_referrer', 'yes');
    } else {
      this._isTwa =
        sessionStorage?.getItem('has_android_twa_referrer') === 'yes';
    }

    this.initialized = true;
  }

  isTwa() {
    return this._isTwa;
  }
}
